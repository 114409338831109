import { BumpResourceSheetInterface } from '@vatos-pas/common';
import { CAN_EDIT_BUMPOUT_TIMESHEET_PAID } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { useEffect, useState } from 'react';
import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useListContext,
  useNotify,
} from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import { DateTime } from 'luxon';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { Show } from 'components/Show';
import InfoDialog from 'components/InfoDialog';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

type PaidCheckboxProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetInterface | any>;

const PaidCheckboxField = (props: PaidCheckboxProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { refetch } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [paidHistory, setPaidHistory] = useState<any[]>([]);

  const [status, setStatus] = useState(!!props.record?.paid);
  const classes = useStyles();

  const isJobPhaseBump = props?.record?.bumpResourceType === 'JOB_PHASE_BUMP';
  const canEdit = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_PAID);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.record?.id) return;
    setStatus(event.target.checked);
    try {
      const params: any = {
        id: props.record.id,
        data: {
          paid: event.target.checked,
        },
        previousData: props.record,
      };

      if (isJobPhaseBump) {
        params.data.jobPhaseBumpId = props.record.id;
      } else {
        params.data.repairPaymentId = props.record.id;
      }

      await dataProvider.create(
        isJobPhaseBump ? '/job-phase-bump-paid' : '/repair-payment-paid',
        params,
      );
      notify('Changes were made successfully!');
      refetch();
    } catch (error) {
      notify(error.message, 'warning');
      setStatus(!event.target.checked);
    }
  };

  // React-admin uses stale-while-revalidate technique
  // Because of that, the first render of this component will always have the old state.
  // We need to update when react-admin passes the revalidated version of record.
  useEffect(() => {
    setStatus(!!props.record?.paid);
  }, [props.record]);

  if (!props.record?.id) return null;

  const openModal = async () => {
    const filter: any = {};
    if (isJobPhaseBump) {
      filter.jobPhaseBumpId = props.record.id;
    } else {
      filter.repairPaymentId = props.record.id;
    }
    const paidHistoryResponse: any = await dataProvider.getList(
      isJobPhaseBump ? '/job-phase-bump-paid' : '/repair-payment-paid',
      {
        filter,
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'DESC' },
      },
    );
    if (paidHistoryResponse.data) {
      setPaidHistory(paidHistoryResponse.data);
    }
    setIsModalOpen(true);
  };

  const getHistoryContent = item => {
    const name = item.user.firstName + ' ' + item.user.lastName;
    const dateFormatted = item.createdAt
      ? DateTime.fromISO(item.createdAt).toFormat('t  MM/dd/yyyy')
      : '';
    return `${name} - ${dateFormatted}`;
  };

  const getTitle = () => {
    return `${props.record.repairType} - Lot # ${props.record.jobLot}`;
  };

  return (
    <Box display="flex" minWidth={80}>
      <Checkbox disabled={!canEdit} checked={status} onChange={handleChange} />
      <Show condition={parseInt(props.record?.countPaidHistory || 0) > 0}>
        <IconButton onClick={openModal}>
          <InfoOutlinedIcon className={classes.infoWarning} />
        </IconButton>
      </Show>
      <InfoDialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title={getTitle()}
        closeText="CLOSE"
        content={
          <>
            <Box mb={2}>
              <Typography style={{ color: 'black', fontWeight: 'bold' }}>
                Payment Status History
              </Typography>
            </Box>
            <Box>
              {paidHistory.map((item, index) => (
                <Box
                  key={item.id}
                  pb={1}
                  mb={index !== paidHistory.length - 1 ? 1 : 0}
                >
                  <Typography style={{ color: 'black', fontWeight: 'normal' }}>
                    {getHistoryContent(item)}
                  </Typography>
                  <Typography style={{ color: 'black', fontWeight: 'normal' }}>
                    Status: {item.paid ? 'Paid' : 'Awaiting Payment'}
                  </Typography>
                </Box>
              ))}
            </Box>
          </>
        }
      />
    </Box>
  );
};

const useStyles = makeStyles({
  infoWarning: {
    color: '#ff9800',
  },
});

export default PaidCheckboxField;
