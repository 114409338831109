import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Input from '@material-ui/core/Input';

export const CurrentCostField = props => {
  if (!props.record?.id) return null;
  const currentFields = props.fields?.[props.record.id];

  if (!props.canViewCostFields) return null;

  return (
    <Box display="flex" minWidth={80}>
      {props.canEditCostFields ? (
        <>
          <Input
            inputProps={{
              min: 0,
              max: 5000,
              title: '',
              step: 'any',
              maxValue: 100,
            }}
            value={
              currentFields && currentFields?.currentCost !== undefined
                ? currentFields.currentCost
                : props.record.currentCost
            }
            fullWidth
            type="number"
            onChange={event => {
              if (parseFloat(event.target.value) >= 100000) {
                return;
              } else {
                props.onChangeField(
                  props.record.id,
                  'currentCost',
                  event.target.value.slice(0, 8),
                );
              }
            }}
          />
        </>
      ) : (
        <>
          <Typography>{props.record.currentCost}</Typography>
        </>
      )}
    </Box>
  );
};
