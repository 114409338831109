import { useState } from 'react';

import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { DateTime } from 'luxon';
import IconButton from '@material-ui/core/IconButton';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import SaveIcon from '@material-ui/icons/Save';

import { Show } from 'components/Show';
import { CAN_LOG_HOURS_BUMPOUT } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { BumpoutTimesheetFields } from '../views/bumpout-timesheet-list';

type ButtonProps = {
  fields: BumpoutTimesheetFields | null;
  isJobPhaseBump: boolean;
  isCostChange: boolean;
  handleRefetch: () => void;
  setFields: any;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const Button = ({
  record,
  fields,
  isJobPhaseBump,
  handleRefetch,
  isCostChange,
  setFields,
}: ButtonProps) => {
  if (!record?.id) return null;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitClick = async () => {
    setIsLoading(true);
    try {
      const data: any = {};
      if (!currentFields) return;
      if (isCostChange) {
        if (
          (record.currentCost || currentFields.currentCost) &&
          record.currentCost !== currentFields.currentCost
        ) {
          data.currentCost = currentFields.currentCost;
        }
        if (
          (record.paidDate || currentFields.paidDate) &&
          record.paidDate !== currentFields.paidDate
        ) {
          data.paidDate = currentFields.paidDate;
        }
      } else {
        data.timeIn =
          currentFields?.timeIn instanceof DateTime &&
          currentFields.timeIn.toFormat('HH:mm:00');
        data.timeOut =
          currentFields?.timeOut instanceof DateTime &&
          currentFields.timeOut.toFormat('HH:mm:00');
      }

      await dataProvider.update(
        isJobPhaseBump ? '/job-phase-bump' : '/repair-payment',
        {
          id: record.id,
          data,
          previousData: record,
        },
      );

      const getCostMessage = () => {
        if (data.paidDate !== undefined && data.currentCost !== undefined) {
          return 'Check Date and Current Cost updated successfully!';
        }
        if (data.paidDate !== undefined) {
          return 'Check Date updated successfully!';
        }
        if (data.currentCost !== undefined) {
          return 'Current Cost updated successfully!';
        }
        return '';
      };

      notify(isCostChange ? getCostMessage() : 'Worked hours logged.');
      handleRefetch();
      setTimeout(() => {
        setFields(null);
      }, 500);
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const currentFields = fields?.[record.id];

  const numberValue =
    typeof currentFields?.workedHours === 'string'
      ? parseFloat(currentFields.workedHours)
      : null;

  const workedHoursDirty =
    record.workedHours && numberValue !== record.workedHours;

  const getDisabled = () => {
    if (isLoading) return true;
    if (!isCostChange) {
      return (
        workedHoursDirty ||
        isLoading ||
        !currentFields?.timeIn ||
        !currentFields?.timeOut ||
        isLoading
      );
    } else {
      if (!currentFields) return true;
      if (
        (record.currentCost &&
          !currentFields.currentCost &&
          currentFields.currentCost !== undefined) ||
        (record.paidDate &&
          !currentFields.paidDate &&
          currentFields.paidDate !== undefined)
      ) {
        return true;
      }
      if (
        (record.currentCost || currentFields.currentCost) &&
        record.currentCost !== currentFields.currentCost &&
        currentFields.currentCost !== undefined
      ) {
        return false;
      }
      if (
        (record.paidDate || currentFields.paidDate) &&
        record.paidDate !== currentFields.paidDate &&
        currentFields.paidDate !== undefined
      ) {
        return false;
      }
      return true;
    }
  };

  return (
    <IconButton
      color="primary"
      size="small"
      disabled={getDisabled()}
      onClick={handleSubmitClick}
    >
      <SaveIcon />
    </IconButton>
  );
};

type SaveButtonProps = {
  fields: BumpoutTimesheetFields | null;
  handleRefetch: () => void;
  isCostChange?: boolean;
  canEditCostFields?: boolean;
  setFields?: any;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const SaveButton = ({
  record,
  fields,
  handleRefetch,
  isCostChange = false,
  setFields,
  canEditCostFields = false,
}: SaveButtonProps) => {
  if (!record?.id) return null;

  const { hasPermission } = PermissionsProvider.useContainer();

  const isBumpoutApproved =
    record?.bumperApprovalStatus === 'APPROVED' ||
    record?.bumperApprovalStatus === 'MISMATCH_APPROVAL' ||
    record?.bumperApprovalStatus === 'OVERRIDDEN';
  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';

  const canLogHours =
    !isBumpoutApproved && hasPermission(CAN_LOG_HOURS_BUMPOUT);
  if (isBumpoutApproved && !isCostChange) return null;

  return (
    <Show condition={isCostChange ? canEditCostFields : canLogHours}>
      <Button
        fields={fields}
        isJobPhaseBump={isJobPhaseBump}
        record={record}
        handleRefetch={handleRefetch}
        isCostChange={isCostChange}
        setFields={setFields}
      />
    </Show>
  );
};

export default SaveButton;
