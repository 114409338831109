import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const PaidDateField: any = ({
  record,
  fields,
  onChangeField,
  canEditCostFields,
  canViewCostFields,
}) => {
  if (!record || !canViewCostFields) return null;

  const { id, paidDate } = record;
  const hasFieldPaidDate = fields && fields[id] && fields[id]?.paidDate;
  const paidDateText = paidDate ? paidDate : null;
  const value = hasFieldPaidDate ? fields[id].paidDate : paidDateText;

  return (
    <Box display="flex" minWidth={150}>
      <KeyboardDatePicker
        disabled={!canEditCostFields}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        fullWidth
        value={value}
        onChange={date => onChangeField(id, 'paidDate', date)}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
      />
    </Box>
  );
};
